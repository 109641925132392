import React, { useEffect } from "react";
import { ReportDetails } from "../styles/CommonReports.styled";

export default function ProductDetail({ productData }) {
  function formatDateTime(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2);

    return (
      <p>
        {`${day}/${month}/${year}`}
        <br />
        {date.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </p>
    );
  }

  return (
    <ReportDetails>
      <div className="table_heading">
        <p>ID</p>
        <p>Machine</p>
        <p>Thickness</p>
        <p>Dimension</p>
        <p>Product Type</p>
        <p>Added On</p>
        {/* <p>Shift</p> */}
      </div>
      {productData.map((item, index) => (
        <div key={index}>
          <hr className="divider" />
          <div className="table_content">
            <p>{index + 1}</p>
            <p>{item?.machine_no}</p>
            <p>{item?.thickness}</p>
            <p>{item?.product_dimensions}</p>
            <p>{item?.product_type}</p>
            {/* convert firebase time stamp to datetime string dd/mm/yy */}

            {item?.creatingDate ? (
              formatDateTime(item.creatingDate.toDate())
            ) : (
              <p>No date</p>
            )}
          </div>
        </div>
      ))}
    </ReportDetails>
  );
}
