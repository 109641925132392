import React, { useState } from "react";
import { ReportsFormContainer } from "../styles/CommonReports.styled";
import { useRef } from "react";
import { ProductTypes, Shifts } from "../shared/constants";
import { ProductThickness, ProductDimension } from "../shared/constants";
import { useEffect } from "react";
import { db_firestore } from "../Hooks/config";
import { doc, getDoc } from "firebase/firestore";

export default function DamagedProductForm({
  setFormData,
  setshowProductModal,
}) {
  let [machineNumList, setMachineNumList] = useState([]);
  let [confirm, setConfirm] = useState(false);
  let [msg, setMsg] = useState("");
  let [lastestProduct, setLatestProduct] = useState({
    machine_no: "",
    thickness: "N/A",
    product_type: "N/A",
    product_dimensions: "N/A",
    shift: "",
  });

  let machine_no = useRef("");
  let damage_count = useRef("");
  let shift = useRef("");

  // get latest product info
  const selectMachine = (number) => {
    const ref = doc(db_firestore, `latest_product`, `machine_${number}`);
    getDoc(ref).then((data) => {
      setLatestProduct(data.data());
    });
  };

  const validation = () => {
    if (
      machine_no.current.value === "" ||
      shift.current.value === "" ||
      damage_count.current.value === ""
    ) {
      setMsg("Please fill up this form properly");
      return false;
    }
    return true;
  };

  const setData = (e) => {
    e.preventDefault();

    if (!validation()) {
      return;
    }

    setFormData({
      damagedProductCount: parseInt(damage_count.current.value),
      machine_no: machine_no.current.value,
      product_dimensions: lastestProduct.product_dimensions,
      product_type: lastestProduct.product_type,
      shift: shift.current.value,
      thickness: lastestProduct.thickness,
      unix_time: Math.floor(Date.now() / 1000),
    });
    setshowProductModal(false);
    setConfirm(false);
  };

  useEffect(() => {
    const ref = doc(db_firestore, `information`, "info");

    getDoc(ref).then((data) => {
      let numList = [];
      const list = data.data();

      numList.push(<optgroup label="Forming Machines"></optgroup>);
      list["forming_machine"].forEach((num, index) => {
        numList.push(
          <option key={index} value={num}>
            {num}
          </option>
        );
      });

      numList.push(<optgroup label="Polish Machines"></optgroup>);
      list["polish_machine"].forEach((num, index) => {
        numList.push(
          <option key={index + numList.length} value={num}>
            {num}
          </option>
        );
      });

      setMachineNumList(numList);
    });
  }, []);

  return (
    <ReportsFormContainer bg={!confirm ? `#E65192` : "gray"}>
      <form onSubmit={setData}>
        <h3>Add Damaged Product Info</h3>

        <label>
          <p>Machine No*</p>

          <select
            ref={machine_no}
            onChange={(e) => {
              selectMachine(e.target.value);
            }}
          >
            <option selected disabled value="">
              Machine No.
            </option>
            {machineNumList}
          </select>
        </label>

        <label
          style={{
            paddingBottom: "10px",
            borderBottom: "1px solid #fff",
          }}
        >
          <p>Dimension:</p>
          {lastestProduct.product_dimensions}
        </label>

        <label
          style={{
            paddingBottom: "10px",
            borderBottom: "1px solid #fff",
          }}
        >
          <p>Thickness:</p>
          {lastestProduct.thickness}
        </label>

        <label
          style={{
            paddingBottom: "10px",
            borderBottom: "1px solid #fff",
          }}
        >
          <p>Product Type:</p>
          {lastestProduct.product_type}
        </label>

        <label
          style={{
            marginBottom: "0px",
          }}
        >
          <p>Shift*</p>
          <select ref={shift}>
            <option selected disabled value="">
              Select Shift
            </option>
            {Shifts.map((shift) => (
              <option value={shift}>{shift}</option>
            ))}
          </select>
        </label>

        <label>
          <p>Count*</p>
          <input
            ref={damage_count}
            type="number"
            min="1"
            required
            placeholder="Damaged Product Count"
          />
        </label>

        <span
          className="msgSpan"
          style={{
            marginBottom: "20px",
          }}
        >
          {msg}
        </span>

        {confirm ? (
          <button className="submit" type="submit">
            Confirm
          </button>
        ) : (
          <input
            value={"Review"}
            type="button"
            className="submit"
            onClick={() => {
              if (validation()) {
                setConfirm(true);
                setMsg("Please Review the form before submitting");
              }
            }}
          />
        )}
      </form>
    </ReportsFormContainer>
  );
}
