import React, { useEffect } from "react";
// import {
//   LineChart,
//   Legend,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   Line,
// } from "recharts";
// import { Select } from '../../../styles/Common.styled';

// import ApexCharts from "apexcharts";
// import Chart from "react-apexcharts";
import { DownTimeWrapper } from "../../../styles/DownTime.styled";
import { Section } from "../../../styles/Sections.styled";
import split_time from "./GraphTime";
import {
  where,
  query,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { useState } from "react";
import { db_firestore } from "../../../Hooks/config";
import { AnalyticsDetail } from "../../../styles/Analytics.styled";
import { useRef } from "react";
import DownTimeGraph from "./DownTimeGraph";
import { set } from "firebase/database";

export default function DownTime() {
  let dateInput = useRef("");
  let [graphData, setGraphData] = useState([]);
  // let [machineNumList, setMachineNumList] = useState([]);
  // let [machine_number, setMachine_number] = useState("");
  let [machineNumbers, setMachineNumbers] = useState("");
  let [loading, setLoading] = useState(false);
  let [DataPeriod, setDataPeriod] = useState(new Date());

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Active",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "Idle",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "Active1",
        data: [93, 72, 89, 75, 115, 80],
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        categories: [],
      },
      legend: {
        show: false,
        // position: "top",
        // horizontalAlign: "left",
      },
      fill: {
        opacity: 1,
      },
      colors: ["#00E396", "#FF4560"],
    },
  });

  useEffect(() => {
    // Setting today's Data
    var date = new Date();
    var day = ("0" + date.getDate()).slice(-2);
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var today = date.getFullYear() + "-" + month + "-" + day;
    dateInput.current.value = today;

    // Collecting Machine No. list
    const ref = doc(db_firestore, `information`, "info");

    getDoc(ref).then((data) => {
      // let numList = [];
      let machineNo = [];
      const list = data.data();

      // numList.push(<optgroup label="Forming Machines"></optgroup>);
      list["forming_machine"].forEach((num, index) => {
        machineNo.push(num);
        // numList.push(
        //   <option key={index} value={num}>
        //     Machine {num}
        //   </option>
        // );
      });

      // numList.push(<optgroup label="Polish Machines"></optgroup>);
      list["polish_machine"].forEach((num, index) => {
        machineNo.push(num);
        //   numList.push(
        //     <option key={index + numList.length} value={num}>
        //       Machine {num}
        //     </option>
        //   );
      });

      setMachineNumbers(machineNo);
    });
  }, []);

  useEffect(() => {
    setData();
  }, [machineNumbers]);

  async function setData() {
    let graphDataArr = [];
    setGraphData([]);

    // Custom data filtering
    let startDate = new Date(DataPeriod);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setMilliseconds(0);
    startDate.setSeconds(0);

    let endDate = new Date(DataPeriod);
    // set hours to 23:59:59 if the date is not today
    if (endDate.getDate() !== new Date().getDate()) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setMilliseconds(999);
      endDate.setSeconds(59);
    }

    startDate = Math.floor(startDate.getTime() / 1000);
    endDate = Math.floor(endDate.getTime() / 1000);

    setLoading(true);
    console.log(startDate, endDate);

    const ref = collection(db_firestore, "machineStatus");

    machineNumbers.forEach(async (num) => {
      let queryResult = [];

      const q = query(
        ref,
        where("time_start", ">=", startDate),
        where("time_start", "<=", endDate),
        where("machine_no", "==", num)
      );

      const snapShot = await getDocs(q);
      snapShot.forEach((doc) => {
        console.log(doc.data(), "-------------------");
        queryResult.push(doc.data());
      });
      graphDataArr.push({
        machine_no: num,
        data: split_time(900, queryResult, startDate, endDate),
      });

      if (graphDataArr.length == machineNumbers.length) {
        setLoading(false);
        setGraphData(graphDataArr);
        // setting machine no list to chart data
        // chartData.options.xaxis.categories = machineNumbers;
        // console.log(chartData);
        setChartData({
          ...chartData,
          options: {
            ...chartData.options,
            xaxis: {
              categories: machineNumbers,
            },
          },
        });
      }
    });
  }

  return (
    <Section bg="#fff">
      <DownTimeWrapper>
        <div>
          <h6 style={{ fontSize: "1.8rem", marginBottom: "2rem" }}>
            Downtime Status Per Machine
          </h6>

          <h6
            style={{
              display: "inline-flex",
              fontSize: "1rem",
              marginBottom: "2rem",
            }}
          >
            <span
              style={{
                width: "1rem",
                height: "1rem",
                background: "red",
                display: "block",
                margin: "0rem 1rem",
              }}
            ></span>{" "}
            Idle
            <span
              style={{
                width: "1rem",
                height: "1rem",
                background: "green",
                display: "block",
                margin: "0rem 1rem",
              }}
            ></span>{" "}
            Active
          </h6>
          {graphData.length === 0 && !loading ? (
            <h1 style={{ fontSize: "1.2rem", marginBottom: "2rem" }}>
              No Data Found
            </h1>
          ) : (
            <DownTimeGraph graphData={graphData} thresholdTime={900} />
          )}

          {loading ? (
            <h1
              style={{
                textAlign: "center",
                color: "gray",
                fontSize: "2rem",
              }}
            >
              <br />
              <br />
              loading ...
            </h1>
          ) : null}
          {/* <LineChart
            width={950}
            height={300}
            data={graphData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Value" stroke="#8884d8" />
          </LineChart> */}
        </div>

        <AnalyticsDetail>
          <div className="category">
            {/* <select
              onChange={(e) => {
                setMachine_number(
                  e.target.options[e.target.selectedIndex].value
                );
              }}
            >
              <option selected disabled>
                Select Machine
              </option>
              {machineNumList}
            </select> */}

            <input
              type="date"
              onChange={(e) => {
                setDataPeriod(e.target.valueAsDate);
              }}
              ref={dateInput}
            />

            <button className="date-btn" onClick={setData}>
              Set
            </button>
          </div>
        </AnalyticsDetail>
      </DownTimeWrapper>
    </Section>
  );
}
