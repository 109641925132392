import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
import forming from "./../../../assets/images/forming.png";
import polish from "./../../../assets/images/polisher.png";
import { useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  where,
} from "firebase/firestore";
import { db_firestore } from "../../../Hooks/config";
import { useState } from "react";

export default function Card(props) {
  let [totalProduct, setTotalProduct] = useState(0);
  let [totalWeight, setTotalWeight] = useState(0);

  useEffect(() => {
    let collectionRef = collection(db_firestore, "machines");
    const q = query(
      collectionRef,
      where("machine_no", "==", props.machineNo),
      orderBy("unix_time", "desc"),
      limit(1)
    );

    onSnapshot(q, (snapShot) => {
      snapShot.forEach((doc) => {
        setTotalProduct(doc.data().count ? doc.data().count : 0);
        setTotalWeight(
          doc.data().weight == "" || NaN || null ? 0 : doc.data().weight
        );
      });
    });
  }, []);

  return (
    <NavLink to={`/machines/${props.machineNo}`}>
      <div
        className={styles.card}
        style={
          props.active
            ? { backgroundColor: "#6bffa9" }
            : { backgroundColor: "rgb(255, 112, 102)" }
        }
      >
        <div className={styles.realtimeData}>
          Total Product: {totalProduct}
          <br />
          Total Weight: {totalWeight} KG
        </div>
        <div className={styles.cardIcon}>
          {props.meta === "forming" ? (
            <img src={forming} alt="Forming Machine" />
          ) : (
            <img src={polish} alt="Polish Machine" />
          )}
        </div>
        <h2>Machine {props.machineNo}</h2>
        <h3>
          {props.active ? (
            <>
              (Active) <br />
              <small>(last updated {props.idleTime} ago)</small>
            </>
          ) : (
            <span style={{ marginTop: "1rem", display: "block" }}>
              (Idle for {props.idleTime})
            </span>
          )}
        </h3>
      </div>
    </NavLink>
  );
}
