import React from "react";
import ProductDetail from "../../../components/ProductDetail";
import ProductForm from "../../../components/ProductForm";
import ShiftForm from "../../../components/ShiftForm";
import Modal from "../../../organism/Modal";
import { ReportsWrapper } from "../../../styles/CommonReports.styled";
import { useState, useEffect } from "react";
import { useFirestore, GetFirestoreData } from "../../../Hooks/firebaseFuncs";
import DamagedProductForm from "../../../components/DamagedProductForm";
// import { set } from "firebase/database";

export default function ProductDetails({
  setshowProductModal,
  showProductModal,
  setshowShiftModal,
  showShiftModal,
  damagedProductModal,
  setdamagedProductModal,
}) {
  let [TableData, setTableData] = useState([]);
  let [formData, setFormData] = useState({});
  let [shiftData, setShiftData] = useState({});
  let [damagedProductData, setDamagedProductData] = useState({});

  GetFirestoreData("products", 65).then((data) => {
    setTableData(data);
  });

  const { addDocument } = useFirestore("products");
  const { updateDocument } = useFirestore("latest_product");

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      addDocument(formData);
      updateDocument(`machine_${formData.machine_no}`, formData);
    }
  }, [formData]);

  const { updateDocument: updateShift } = useFirestore("shiftTime");

  useEffect(() => {
    if (Object.keys(shiftData).length !== 0) {
      updateShift(shiftData["shift"], shiftData);
      setshowShiftModal(false);
    }
  }, [shiftData]);

  const { addDocument: addDamagedProduct } = useFirestore("damagedProducts");

  useEffect(() => {
    if (Object.keys(damagedProductData).length !== 0) {
      addDamagedProduct(damagedProductData);
      setdamagedProductModal(false);
    }
  }, [damagedProductData]);

  return (
    <ReportsWrapper>
      {/* Table */}
      <ProductDetail productData={TableData} />

      {showProductModal && (
        <Modal>
          <div onClick={() => setshowProductModal(false)} className="button">
            X
          </div>
          <ProductForm
            setFormData={setFormData}
            setshowProductModal={setshowProductModal}
          />
        </Modal>
      )}

      {showShiftModal && (
        <Modal>
          <div onClick={() => setshowShiftModal(false)} className="button">
            X
          </div>
          <ShiftForm
            setShiftData={setShiftData}
            setshowProductModal={setshowProductModal}
          />
        </Modal>
      )}

      {damagedProductModal && (
        <Modal>
          <div onClick={() => setdamagedProductModal(false)} className="button">
            X
          </div>
          <DamagedProductForm
            setFormData={setDamagedProductData}
            setshowProductModal={setdamagedProductModal}
          />
        </Modal>
      )}
    </ReportsWrapper>
  );
}
