import styled from "styled-components";

export const CrainReportTableWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 0.8rem;
  .table_heading {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 700;
  }

  .table_content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1rem;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    text-align: center;
  }

  .divider {
    color: #dcdbdb;
    border-width: 1px;
    border-color: aliceblue;
  }
`;
