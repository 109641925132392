import { DashboardContent } from "../../styles/Dashboard.styled";
import { ReportHeadingWrapper } from "../../styles/Reports.styled";
import { CrainReportTableWrapper } from "./CrainTableWrapper";
import { TareReportTableWrapper } from "./TareTableWrapper";
import { useEffect } from "react";
import { useState } from "react";
import { db_firestore } from "../../Hooks/config";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { Button } from "../../styles/Common.styled";

function formatUnixTime(unixTime) {
  const date = new Date(unixTime * 1000);
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
}

function formatDuration(timeStart, timeEnd) {
  const duration = timeEnd - timeStart;
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration % 3600) / 60);
  let seconds = duration % 60;

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

export default function TareAndCrainUpdates() {
  let [ReportData, setReportData] = useState([]);
  let [isTare, setIsTare] = useState(true);

  useEffect(() => {
    const ref = collection(
      db_firestore,
      isTare ? "tareUpdates" : "crainLiftingUpdates"
    );
    const q = query(ref, orderBy("unix_time", "desc"), limit(50));
    onSnapshot(q, (snapShot) => {
      let items = [];
      snapShot.forEach((doc) => {
        items.push(doc.data());
      });
      setReportData(items);
    });
  }, [isTare]);

  return (
    <DashboardContent>
      <ReportHeadingWrapper>
        <p>Tare and Crain Lifting Updates</p>
        <div className="right">
          <Button
            bg={!isTare ? "#E65192" : "black"}
            onClick={() => {
              setIsTare(true);
            }}
          >
            Tare Updates
          </Button>
          <Button
            bg={isTare ? "#E65192" : "black"}
            onClick={() => {
              setIsTare(false);
            }}
          >
            Crain Lifting Updates
          </Button>
        </div>
      </ReportHeadingWrapper>
      {isTare ? (
        <TareReportTableWrapper>
          <div className="table_heading">
            <p>ID</p>
            <p>Machine Number</p>
            <p>Tared On</p>
          </div>
          {ReportData.map((item, index) => (
            <span key={item.id}>
              <hr className="divider" />
              <div className="table_content">
                <p>{index + 1 + parseInt(0)}</p>
                <p>{item?.machine_no}</p>
                <p>{formatUnixTime(item?.unix_time)}</p>
              </div>
            </span>
          ))}
        </TareReportTableWrapper>
      ) : (
        <CrainReportTableWrapper>
          <div className="table_heading">
            <p>ID</p>
            <p>Machine Number</p>
            <p>Duration</p>
            <p>Tared On</p>
          </div>
          {ReportData.map((item, index) => (
            <span key={item.id}>
              <hr className="divider" />
              <div className="table_content">
                <p>{index + 1 + parseInt(0)}</p>
                <p>{item?.machine_no}</p>
                <p>{formatDuration(item?.time_start, item?.time_end)}</p>
                <p>{formatUnixTime(item?.unix_time)}</p>
              </div>
            </span>
          ))}
        </CrainReportTableWrapper>
      )}
    </DashboardContent>
  );
}
