import React, { useState } from "react";
import { DashboardContent } from "../../styles/Dashboard.styled";
import { ProtectedRoute } from "../Authentication/ProtectedRoute";
import ManageHeading from "./components/ManageHeading";
import ProductDetails from "./components/ProductDetails";

export default function ManageProducts() {
  let [showProductModal, setshowProductModal] = useState(false);
  let [showShiftModal, setshowShiftModal] = useState(false);
  let [damagedProductModal, setdamagedProductModal] = useState(false);

  return (
    <ProtectedRoute permission="manageProduct">
      <DashboardContent>
        <ManageHeading
          setshowProductModal={setshowProductModal}
          setshowShiftModal={setshowShiftModal}
          setdamagedProductModal={setdamagedProductModal}
        />
        <ProductDetails
          setshowProductModal={setshowProductModal}
          showProductModal={showProductModal}
          setshowShiftModal={setshowShiftModal}
          showShiftModal={showShiftModal}
          damagedProductModal={damagedProductModal}
          setdamagedProductModal={setdamagedProductModal}
        />
      </DashboardContent>
    </ProtectedRoute>
  );
}
