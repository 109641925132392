// export const MachineNo = ["32", "34"];
export const ProductTypes = [
  "Round Pipe",
  "Rectangular Pipe",
  "Square Pipe",
  "Design Pipe",
  "Spiral Pipe",
  "Half Round Pipe",
  "Schedule Pipe",
];
export const Shifts = ["Morning", "Night"];
export const userTypes = [
  "manager",
  "admin",
  "owner",
  "in-charge",
  "machine-operator",
];
export const ProductThickness = [
  `0.4mm`,
  `0.5mm`,
  `0.6mm`,
  `0.7mm`,
  `0.8mm`,
  `1.0mm`,
  `1.2mm`,
  `1.5mm`,
  `2.0mm`,
  `2.5mm`,
];
export const ProductDimension = [
  "3/8 inch",
  "5/8 inch",
  "1 inch",
  "11/2 inch",
  "21/2 inch",
  `3/8 inch x 3/8 inch`,
  `1/2 inch x 3/4 inch`,
  `1/2 inchx11/2 inch`,
  `5/8 inch x 5/8 inch`,
  `3/4 inch x 11/2 inch`,
  `1 inch x 11/2 inch`,
  `11/2 inch x 11/2 inch`,
  `2 inch x 1 inch`,
  `21/2 inch x 1 inch`,
  `3 inch x 1 inch`,
  `3 inch x 2 inch`,
  `3 inch x 3 inch`,
  `3 inch x 11/2 inch`,
  `1/2 inch`,
  `3/4 inch`,
  `11/4 inch`,
  `2 inch`,
  `3 inch`,
  `4 inch`,
  `1/2 inch x 1/2 inch`,
  `1/2 inch x 1 inch`,
  `1/2 inch x 2 inch`,
  `3/4 inch x 3/4 inch`,
  `1 inch x 1 inch`,
  `11/4 inch x 11/4 inch`,
  `11/2 inch x 2 inch`,
  `2 inch x 2 inch`,
  `21/2 inch x 11/2 inch`,
  `3 inch x 11/4 inch`,
  `61 inch x 29.5 inch`,
  `4 inch x 2 inch`,
];
