import React, { useState } from "react";
import { ReportsFormContainer } from "../styles/CommonReports.styled";
import { useRef } from "react";
import { ProductTypes, Shifts } from "../shared/constants";
// import { ProductThickness, ProductDimension } from "../shared/constants";
import { useEffect } from "react";
import { db_firestore } from "../Hooks/config";
import { doc, getDoc } from "firebase/firestore";

export default function ShiftForm({ setShiftData, setshowProductModal }) {
  let [msg, setMsg] = useState("");
  let [selectedShift, setSelectedShift] = useState(Shifts[0]);
  let [shiftInfo, setShiftInfo] = useState({});

  let starts = useRef("");
  let ends = useRef("");
  let shift = useRef("");

  const setData = (e) => {
    e.preventDefault();

    if (
      starts.current.value === "" ||
      ends.current.value === "" ||
      shift.current.value === ""
    ) {
      setMsg("Please fill up this form properly");
      return false;
    }

    // convert ends and starts to unix timestamp
    let startsTime = starts.current.valueAsNumber / 1000;
    let endsTime = ends.current.valueAsNumber / 1000;

    setShiftData({
      starts: startsTime,
      ends: endsTime,
      shift: shift.current.value,
    });

    setshowProductModal(false);
  };

  useEffect(() => {
    Shifts.forEach((shift) => {
      let ref = doc(db_firestore, `shiftTime`, shift);
      getDoc(ref).then((data) => {
        setShiftInfo((prev) => {
          return {
            ...prev,
            [shift]: data.data(),
          };
        });
      });
    });
  }, []);

  useEffect(() => {
    if (Object.keys(shiftInfo).length !== 0) {
      // HH:MM format
      let starts_hour = parseInt(shiftInfo[selectedShift].starts / 3600);
      let starts_min = parseInt((shiftInfo[selectedShift].starts % 3600) / 60);

      let ends_hour = parseInt(shiftInfo[selectedShift].ends / 3600);
      let ends_min = parseInt((shiftInfo[selectedShift].ends % 3600) / 60);

      starts.current.value = `${
        starts_hour < 9 ? `0${starts_hour}` : starts_hour
      }:${starts_min < 9 ? `0${starts_min}` : starts_min}`;

      ends.current.value = `${ends_hour < 9 ? `0${ends_hour}` : ends_hour}:${
        ends_min < 9 ? `0${ends_min}` : ends_min
      }`;
    }
  }, [selectedShift, shiftInfo]);

  return (
    <ReportsFormContainer bg="#E65192">
      <form onSubmit={setData}>
        <h3>Shift time</h3>

        <label>
          <p>Shift*</p>
          <select
            ref={shift}
            onChange={(e) => setSelectedShift(e.target.value)}
          >
            {Shifts.map((shift) => (
              <option value={shift}>{shift}</option>
            ))}
          </select>
        </label>

        <label>
          <p>Starts*</p>
          <input type="time" ref={starts} />
        </label>

        <label>
          <p>Ends*</p>
          <input type="time" ref={ends} />
        </label>

        <button className="submit" type="submit">
          Submit
        </button>

        <span className="msgSpan">{msg}</span>
      </form>
    </ReportsFormContainer>
  );
}
